import {
  addNavMenuItem, ModalService,
  registerBulkAction,
} from '@vendure/admin-ui/core';
import { MaterialListComponent } from './components/material-list/material-list.component';

export default [
  addNavMenuItem(
    {
      id: 'materials',
      label: 'Materials',
      routerLink: ['/extensions/materials'],
      // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
      icon: 'color-palette',
    },
    'catalog',
  ),
  registerBulkAction({
    location: 'material-list',
    label: 'Delete',
    // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
    icon: 'trash',
    onClick: ({ injector, hostComponent, selection }) => {
      const modalService = injector.get(ModalService);
      modalService
        .dialog({
          title: `Delete ${selection.length} material(s)?`,
          buttons: [
            { type: 'secondary', label: 'cancel' },
            { type: 'primary', label: 'send', returnValue: true },
          ],
        })
        .subscribe(response => {
          if (response) {
            (hostComponent as MaterialListComponent).deleteMany(selection.map((x) => x.id));
          }
        });
    },
  }),
];
