import { registerCustomDetailComponent } from '@vendure/admin-ui/core';
import { SuggestedFacetsComponent } from './suggested-facets-component/suggested-facets.component';
import { VariantSuggestedFacetsComponent } from './variant-suggested-facets-component/variant-suggested-facets.component';

export default [
  registerCustomDetailComponent({
    locationId: 'product-detail',
    component: SuggestedFacetsComponent,
  }),
  registerCustomDetailComponent({
    locationId: 'product-variant-detail',
    component: VariantSuggestedFacetsComponent,
  }),
];
