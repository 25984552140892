<div *ngIf="!!rowItem.customFields.config; else noConfig">
  <a class="button-ghost"
     [routerLink]="[
           '/extensions/configs',
           rowItem.customFields.config.id,
           ]">
    <span>Config {{ rowItem.customFields.config.id }}</span>
    <clr-icon shape="arrow right"></clr-icon>
  </a>
</div>
<ng-template #noConfig>
  <a class="button-ghost"
     [routerLink]="[
           '/catalog/products',
           rowItem.productVariant.productId,
           'variants',
           rowItem.productVariant.id
           ]">
    <span>{{ rowItem.productVariant.sku }}</span>
    <clr-icon shape="arrow right"></clr-icon>
  </a>
</ng-template>