import { Component, Input } from '@angular/core';
import { CustomColumnComponent, SharedModule } from '@vendure/admin-ui/core';
import { OrderLine } from '@vendure/core';
import { Config } from '../../gql/graphql';

declare module '@vendure/core/dist/entity/custom-entity-fields' {
  interface CustomOrderLineFields {
    config?: Config | null;
  }
}

@Component({
  selector: 'order-product-variant-sku',
  templateUrl: './order-product-variant-sku.component.html',
  standalone: true,
  imports: [SharedModule],
})
export class OrderProductVariantSkuComponent implements CustomColumnComponent {
  @Input()
  rowItem: OrderLine;
}
