<ng-container *ngIf="requiredFacets$ | async as requiredFacets">
  <ng-container *ngIf="status$ | async as status">
    <clr-accordion>
      <clr-accordion-panel [clrAccordionPanelOpen]="status === 'incomplete'">
        <clr-accordion-title>
          <div class="flex center">
            <span class="">Suggested facets</span>
            <div class="flex-spacer"></div>
            <vdr-chip
              colorType="success"
              *ngIf="status === 'complete'; else incomplete"
            >
              <cds-icon shape="check-circle"></cds-icon>
              complete</vdr-chip
            >
            <ng-template #incomplete>
              <vdr-chip colorType="warning">
                <cds-icon shape="exclamation-triangle"></cds-icon>
                incomplete</vdr-chip
              >
            </ng-template>
          </div>
        </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <div
            *ngFor="let entry of requiredFacets"
            class="flex center facet-row"
          >
            <div class="facet-name">{{ entry.facet.name }}</div>
            <ng-select
              [items]="entry.facet.values"
              [ngModel]="entry.selectedValues"
              [addTag]="false"
              [hideSelected]="true"
              (add)="addFacetValue($event)"
              (remove)="removeFacetValue($event)"
              multiple="true"
              appendTo="body"
              bindLabel="name"
            >
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <vdr-facet-value-chip
                  [facetValue]="item"
                  [removable]="!readonly"
                  (remove)="clear(item)"
                ></vdr-facet-value-chip>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <vdr-facet-value-chip
                  [facetValue]="item"
                  [removable]="false"
                ></vdr-facet-value-chip>
              </ng-template>
            </ng-select></div
        ></clr-accordion-content>
      </clr-accordion-panel>
    </clr-accordion>
  </ng-container>
</ng-container>
